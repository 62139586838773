import React, { useState, createContext } from "react";
import { PlaceEvent } from "./place";
import ModelSearch from "./search/modelSearch";
import { Link } from "react-router-dom";
import EventCardList from "./eventCardList";
import MapBox from "../map/mapbox";
import EventDetails from "./eventDetails";
import WarningBox from "./warningBox";


function EventsPage() {

    const [input, setInput] = useState<string>("");
    const [allEvents, setAllEvents] = useState<PlaceEvent[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<PlaceEvent>(new PlaceEvent());

    const [mapHover, setMapHover] = useState<boolean>(false);
    const [mapHoverId, setMapHoverId] = useState<string>("");
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [hasDay, setHasDay] = useState<boolean>(false);

    const [warning, setWarning] = useState<boolean>(false);


    function setSelectedFromClick(place_id: string): void {
        let foundPlace = allEvents.find(place => place.place_id === place_id);
        if (foundPlace) {
            setSelectedEvent(new PlaceEvent(foundPlace));
        } else {
            let newPlace = new PlaceEvent()
            newPlace.name = "No Place Found"
            setSelectedEvent(newPlace)
        }
    }

    return(
        <div>
            <div className="events__header">
                <Link to="/">
                    <img 
                        src={"MoblitLogo.svg"} // replace with your logo's path
                        alt="Home"
                        className="events__logo"
                    />
                </Link>
                <ModelSearch
                    input={input}
                    setInput={setInput}
                    setAllEvents={setAllEvents}
                    loading={loading}
                    setLoading={setLoading}
                    setShowDetails={setShowDetails}
                    setHasDay={setHasDay}
                    setWarning={setWarning}/>
                </div>
            {/* {warning ? <WarningBox setWarning={setWarning}/> : null} */}
            <MapBox
                places={allEvents}
                hover={mapHover}
                setHover={setMapHover} 
                mapHoverId={mapHoverId}
                setMapHoverId={setMapHoverId}
                setSelectedFromClick={setSelectedFromClick}
                loading={loading}
                setShowDetails={setShowDetails}
                selectedEvent={selectedEvent}/>
            <EventDetails
                selectedEvent={selectedEvent}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
                />
            <EventCardList
                places={allEvents}
                hover={mapHover}
                setHover={setMapHover}
                mapHoverId={mapHoverId}
                selectedEvent={selectedEvent}
                setMapHoverId={setMapHoverId}
                setSelectedFromClick={setSelectedFromClick}
                setShowDetails={setShowDetails}
                hasDay={hasDay}/>
        </div>
    )
}

export default EventsPage;