import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./home.css";

const HomeInput: React.FC = () => {
  const [input, setInput] = useState<string>("");
  const navigate = useNavigate();

  const placeholder = 'Tell me about your travel plans. "I want a beautiful 3-day trip around the French countryside"';

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Navigate to a events route and pass along the state
    navigate('/events', { state: { input } });
  }

  return (
    <div className="inputContainer">
      <form onSubmit={handleSubmit}>
        <input 
          className="homeinput"
          type="text" 
          value={input}
          onChange={e => setInput(e.target.value)}
          placeholder={placeholder}
        />
        <button className="cta">
          <span>Submit</span>
          <svg viewBox="0 0 13 10" height="10px" width="15px">
            <path d="M1,5 L11,5"></path>
            <polyline points="8 1 12 5 8 9"></polyline>
          </svg>
        </button>
      </form>
    </div>
  );
}

export default HomeInput;
