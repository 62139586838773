// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
*,
:after,
:before {
  box-sizing: border-box;
  padding: 10;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;;;EAGE,sBAAsB;EACtB,WAAW;EACX,SAAS;AACX","sourcesContent":["\n*,\n:after,\n:before {\n  box-sizing: border-box;\n  padding: 10;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
