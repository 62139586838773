import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './home/home';
import EventsPage from './events/eventsPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {LoadScript } from '@react-google-maps/api';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="events" element={<EventsPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
