import React, { useEffect, useState, useRef } from "react";
import InputMessage from "./inputMessage";
import { PlaceEvent, IsNotTravelRecommendation } from "../place";

interface OutputProps {
    input: string;
    setInput: React.Dispatch<React.SetStateAction<string>>;
    setAllEvents: React.Dispatch<React.SetStateAction<PlaceEvent[]>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
    setHasDay: React.Dispatch<React.SetStateAction<boolean>>;
    setWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

function ModelSearch({input, setInput, setAllEvents, loading, setLoading, setShowDetails, setHasDay, setWarning}: OutputProps) {

    const [currentEvent, setCurrentEvent] = useState<PlaceEvent>(new PlaceEvent())
    const controllerRef = useRef<AbortController | null>(null);

    const [previousPlaceId, setPreviousPlaceId] = useState<string>("");

    const submitResponse = async (input: string) => {

        setShowDetails(false)
        setLoading(true)

        if (input.trim() === "") {
            // Skip if input is empty
            setWarning(true);
            return
        }
        else if (input.toLowerCase().includes(" day") || input.toLowerCase().includes(" week")) {
            setHasDay(true)
        }
        else {
            setHasDay(false)
        }

        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        setWarning(false)

        // Reset state to empty
        setAllEvents([])
        setCurrentEvent(new PlaceEvent())

        controllerRef.current = new AbortController();

        let headers = new Headers()
        headers.set('Content-Type', 'application/json')

        let url = "https://api.moblit.ai/message/"


        try {
            const response = await fetch(url,
                {
                    method: 'POST',
                    headers:new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({"message": input}),
                    signal: controllerRef.current.signal
                });

            if (response && response.body) {
                const reader = response.body.getReader();

                var decoder = new TextDecoder()

                let currentDay: number = 1

                while (true) {
                    const {value, done} = await reader.read();


                    let decoded: string = decoder.decode(value)

                    if (decoded.includes("\n") || done) {
                        let addedDecoded = " "+decoded+" "
                        let splitArr = addedDecoded.split("\n")
                        currentEvent.text += splitArr[0].trim()
                        let parseResult: boolean | number = currentEvent.parseText()
                        if (typeof(parseResult) === "number") {
                            currentDay = parseResult
                        }
                        else if (parseResult) {
                            currentEvent.mappedDay = currentDay
                            await currentEvent.getInfo()
                            if (!(currentEvent.place_id === undefined) || previousPlaceId !== currentEvent.place_id) {
                                // Create a copy of currentEvent after the getInfo() async operation is done
                                const currentEventCopy = new PlaceEvent(currentEvent);
                                setAllEvents((prevEvents: PlaceEvent[]) => [...prevEvents, currentEventCopy]);
                                setPreviousPlaceId(currentEvent.place_id!)
                            }
                        }
                        else {
                            console.error("Error or non-travel recommendations")
                            console.error(currentEvent.text, decoded)
                            setWarning(true)
                        }

                        // Add any following chars after \n to the next event
                        if (splitArr.length > 1) {
                            currentEvent.text = splitArr[1].trim();
                        }
                        
                        // clear currentEvent and set it to a new PlaceEvent
                        setCurrentEvent(new PlaceEvent());
                    }
                    else {
                        currentEvent.text += decoded
                    }
                    if (done) {
                        setLoading(false)
                        break;
                    }
                }
            }
        } catch (error: unknown) {
            if (error instanceof IsNotTravelRecommendation) {
                setWarning(true)
                setLoading(false)
            }
            if (error instanceof Error) {
                console.log("Error: " + error.message);
            }
        }

    }


    return(
        <div>
            <InputMessage
                input={input}
                setInput={setInput}
                submitResponse={submitResponse}
                loading={loading}/>
        </div>
    )
}

export default ModelSearch;