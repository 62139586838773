
export class IsNotTravelRecommendation extends Error {
    constructor(message: string) {
        super(message);
        this.name = "IsNotTravelRecommendation";
    }
  }


export class PlaceEvent {
    // From GPT API Plain Text Event
    request_id: number | undefined;
    text: string = "";

    // From GPT API Parsed Text
    name: string | undefined;
    gpt_latlong: string | undefined;
    definition: string | undefined;
    mappedDay: number | undefined;

    // From Google Places API
    true_name: string | undefined;
    place_id: string | undefined;
    address: string | undefined;
    phone_number: string | undefined;
    url: string | undefined;
    true_lat: number | undefined;
    true_lng: number | undefined;
    photoRefs: Array<string> | undefined;
    rating: string | undefined;
    openingHours: Array<Map<string, number>> | undefined;
    predictedCity: string | undefined;

    constructor(initializer?: any) {
        if (!initializer) return;
        if (initializer.name) this.name = initializer.name;
        if (initializer.gpt_latlong) this.gpt_latlong = initializer.gpt_latlong;
        if (initializer.definition) this.definition = initializer.definition;
        if (initializer.true_lat) this.true_lat = initializer.true_lat;
        if (initializer.true_lng) this.true_lng = initializer.true_lng;
        if (initializer.address) this.address = initializer.address;
        if (initializer.place_id) this.place_id = initializer.place_id;
        if (initializer.phone_number) this.phone_number = initializer.phone_number;
        if (initializer.url) this.url = initializer.url;
        if (initializer.request_id) this.request_id = initializer.request_id;
        if (initializer.text) this.text = initializer.text;
        if (initializer.mappedDay) this.mappedDay = initializer.mappedDay;
        if (initializer.photoRefs) this.photoRefs = initializer.photoRefs;
        if (initializer.rating) this.rating = initializer.rating;
        if (initializer.openingHours) this.openingHours = initializer.openingHours;
        if (initializer.predictedCity) this.predictedCity = initializer.predictedCity;
    }

    async isLoading(): Promise<boolean> {
        if (this.true_name == undefined) {
            await this.getInfo();
            return true;
        }
        return false;
    }

    async getInfo(): Promise<void> {
        let url = "https://api.moblit.ai/place/"
        try {
            const response = await fetch(url,
                {
                    method: 'POST',
                    headers:new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({"place_name": this.name + " " + this.predictedCity, "lat_long": this.gpt_latlong})
                });

            if (!response.ok) {
                console.log("ERROR: Failed to get place info: "+this.name)
                this.place_id = undefined;
                return
            }

            const data = await response.json();

            this.true_name = data.name;
            this.address = data.address;
            this.place_id = data.place_id;
            this.phone_number = data.phone_number;
            this.url = data.url;
            this.true_lat = Number(data.lat);
            this.true_lng = Number(data.lng);
            this.photoRefs = data.photo_references;
            if (data.rating == "" || data.rating == null || data.rating == undefined) {
                this.rating = "N/A";
            }
            else {
                this.rating = data.rating;
            }
            this.openingHours = [];
            if (data.opening_hours.periods != undefined) {
                try {
                    for (let i = 0; i < data.opening_hours.periods.length; i++) {
                        let timeMap = new Map<string, number>();
                        timeMap.set("open", Number(data.opening_hours.periods[i].open.time));
                        timeMap.set("close", Number(data.opening_hours.periods[i].close.time));
                        this.openingHours.push(timeMap);
                        }
                    }
                catch (TypeError) {
                    this.openingHours = [];
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    toString(){
        return "Name: " + this.name + "\n" + "Address: " + this.address + "\n" + "Phone Number: " + this.phone_number + "\n" + "URL: " + this.url + "\n" + "True Lat: " + this.true_lat + "\n" + "True Lng: " + this.true_lng + "\n";
    }

    parseText(): boolean | number {
        // Return false if text is empty or given not travel recommendation prompt
        // Return number for given the current day

        const dayRegex = /\+* *Day (\d+)\:*/;
        let dayMatch = this.text.match(dayRegex);

        if (dayMatch){
            let parsed: number =  parseInt(dayMatch[1]);
            if (Number.isNaN(parsed)) {
                console.log("WARNING: Failed to parse day: "+this.text)
                return false;
            }
            return parsed;
        }
        else if (this.text.includes("I can only provide travel recommendations")){
            throw new IsNotTravelRecommendation("Not a travel recommendation prompt");
        }

        let textSplit: Array<string> = this.text.split("|:|");
        if (textSplit.length < 4) {
            console.log("WARNING: Failed to parse text: "+this.text)
            return false;
        }
        this.name = textSplit[0].trim();
        this.gpt_latlong = textSplit[1].trim();
        this.definition = textSplit[2].trim();
        this.predictedCity = textSplit[3].trim();

        return true
    }
}