import React from "react";
import HomeInput from "./homeInput";
import "./home.css";


function Home() {

  const refreshPage = () => {
    window.location.reload();
  };

  return(
  <div>
    <div onClick={refreshPage}>
      <img className="home__logo" src="MoblitLogo.svg" alt='Refresh Page'></img>
      <h1 className="title__moblit">Moblit</h1>
    </div>
    <h1 className="home__aiheader">Powered By OpenAI GPT</h1>
    <HomeInput></HomeInput>
  </div>
  )
}

export default Home;
