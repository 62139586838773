// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/markers/mapmarker.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/markers/hovermapmarker.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-map {
  position: relative;
    width: 50vw;
    height: 100vh;
    float: right;
  }

.marker,
.marker-hover {
  background-size: cover;
  width: 45px;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: black;
  transform: translate(-50%, -50%);
}

.marker {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.marker-hover {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
`, "",{"version":3,"sources":["webpack://./src/map/map.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;IAChB,WAAW;IACX,aAAa;IACb,YAAY;EACd;;AAEF;;EAEE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,gCAAgC;AAClC;;AAEA;EACE,yDAA2D;AAC7D;;AAEA;EACE,yDAAgE;AAClE","sourcesContent":["#main-map {\n  position: relative;\n    width: 50vw;\n    height: 100vh;\n    float: right;\n  }\n\n.marker,\n.marker-hover {\n  background-size: cover;\n  width: 45px;\n  height: 45px;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  font-size: 12px;\n  font-weight: bold;\n  color: black;\n  transform: translate(-50%, -50%);\n}\n\n.marker {\n  background-image: url('../../public/markers/mapmarker.svg');\n}\n\n.marker-hover {\n  background-image: url('../../public/markers/hovermapmarker.svg');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
