import React from 'react'
import { PlaceEvent } from './place'
import { Link } from 'react-router-dom'

interface EventDetailsProps {
    selectedEvent: PlaceEvent
    showDetails: boolean
    setShowDetails: React.Dispatch<React.SetStateAction<boolean>>
}

function EventDetails({selectedEvent, showDetails, setShowDetails}: EventDetailsProps) {

    return (
        showDetails ? (
        <div className="event-details">
            <div className="event-details-title">
            <button className="event-details-close" onClick={() => setShowDetails(false)}>
                <img src="details/detail-close.svg" alt="close" className="event-details-image" />
            </button>
                <h1>{selectedEvent.name}</h1>
            </div>
            <div className="eventDetailsBody">
                <img src="details/review_stars.svg" className="event-details__star"/>
                <p className="event-details__rating">{selectedEvent.rating}</p>
                <Link className="event-details__googleicon" to={selectedEvent.url!} target="_blank" rel="noopener noreferrer">
                    <img src="details/googlemaps.svg" 
                        style={{cursor: "pointer", width: "7vh", height: "4vh", margin: "0px"}
                        }/>
                </Link>
                <p className="event-details__description">{selectedEvent.definition}</p>
                <br/>
                <p className="event-details__address">{selectedEvent.address}</p>
            </div>
        </div>
        ) : null
    )
}

export default EventDetails