import React, { useRef, useEffect, useState } from 'react';
import mapboxgl, {Popup} from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import './map.css';
import { PlaceEvent } from '../events/place';
 
mapboxgl.accessToken = 'pk.eyJ1Ijoid29vaXNqaW4iLCJhIjoiY2xqeG1odWJjMXVrZTNtbWlleTUyOXAxYSJ9.5I0Um9ElIsVdob8oczkx_g';


interface MapAPIProps {
    places: PlaceEvent[]
    hover: boolean
    setHover: React.Dispatch<React.SetStateAction<boolean>>
    mapHoverId: string
    setMapHoverId: React.Dispatch<React.SetStateAction<string>>
    loading: boolean
    setSelectedFromClick: (place_id: string) => void
    setShowDetails: React.Dispatch<React.SetStateAction<boolean>>
    selectedEvent: PlaceEvent
  }


function MapBox({places, hover, setHover, mapHoverId, setMapHoverId, loading, setSelectedFromClick, setShowDetails, selectedEvent}: MapAPIProps) {

    const mapContainer = useRef(null);
    const map = React.useRef<mapboxgl.Map | null>(null)

    const [markers, setMarkers] = useState<Record<string, mapboxgl.Marker>>({});
    const [previousHoverId, setPreviousHoverId] = useState<string>("");

    function getLngLatZoom() {
        // Create a bounding box
        let lngs = places.map(place => Number(place.true_lng!));
        let lats = places.map(place => Number(place.true_lat!));
        let maxLng = Math.max(...lngs);
        let minLng = Math.min(...lngs);
        let maxLat = Math.max(...lats);
        let minLat = Math.min(...lats);

        // Calculate the center
        let centerLng = (maxLng + minLng) / 2;
        let centerLat = (maxLat + minLat) / 2;

        // Approximate the zoom level
        let lngDiff = maxLng - minLng;
        let latDiff = maxLat - minLat;

        let zoom = Math.min(Math.log2(360 / lngDiff), Math.log2(180 / latDiff)
        );
        if (zoom > 12) zoom = 12;

        return {centerLng, centerLat, zoom};
    }

    function zoomToPlace(place: PlaceEvent) {
        map.current!.flyTo({
            curve: 2.3,
            center: [place.true_lng!, place.true_lat! - 0.008],
            essential: true,
            zoom: 13});
    }

    function handleHover(place: PlaceEvent) {

        setMapHoverId(place.place_id!);
        setHover(true);
    }

    function handleHoverOut() {
        setHover(false);
    }

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current!,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [-77, 38],
            zoom: 2
        });
    });

    useEffect(() => {
        if (!map.current || !mapContainer.current) return; // wait for map to initialize
        let {centerLng, centerLat, zoom} = getLngLatZoom()

        if (places.length > 0) {
            
            if (!map.current.isMoving() || loading == false) {
                map.current.flyTo({
                    curve: 2.3,
                    center: [centerLng, centerLat],
                    essential: true,
                    zoom: zoom});
                }
            }
        else if (Object.keys(markers).length > 0) {
            for (const marker in markers) {
                markers[marker].remove();
            }
            setMarkers({}); // reset the markers set
        }

        // add markers to map
        places.map((place: PlaceEvent, index) => {

            if (markers && !markers[place.place_id!]) {
                
                // create a HTML element for each feature
                const el = document.createElement('div');
                el.className = 'marker';
                el.id = index.toString();
                el.textContent = (index + 1).toString();
            
                // make a marker for each feature and add to the map
                const marker = new mapboxgl.Marker(el).setLngLat([place.true_lng!, place.true_lat!]).addTo(map.current!);

                const popup = new Popup({
                    offset: 25,
                    closeButton: false,
                }).setHTML(`<h3>${place.name}</h3>`); // add HTML content to the popup. You can customize this to display the info you want

                marker.setPopup(popup);
        
                marker.getElement().addEventListener('mouseenter', () => {
                    // Open the popup when mouse enters the marker
                    marker.togglePopup();
                    handleHover(place);
                });

                marker.getElement().addEventListener('mouseleave', () => {
                    // Close the popup when mouse leaves the marker
                    marker.togglePopup();
                    handleHoverOut();
                });

                marker.getElement().addEventListener('click', () => {
                    setSelectedFromClick(place.place_id!);
                    setShowDetails!(true)
                });

                setMarkers({...markers, [place.place_id!]: marker});
                }
            }
        )

    }, [places]);

    useEffect(() => {

        if (hover == false) {
            if (markers[previousHoverId]){
                markers[previousHoverId].getElement().classList.remove('marker-hover')
                markers[previousHoverId].getElement().classList.add('marker')
            }
            if (markers[mapHoverId]){
                markers[mapHoverId].getElement().classList.remove('marker-hover')
                markers[mapHoverId].getElement().classList.add('marker')
            }
        }
        else {

            if (markers[previousHoverId]){
                markers[previousHoverId].getElement().classList.add('marker')
                markers[previousHoverId].getElement().classList.remove('marker-hover');
            }

            if (markers[mapHoverId]){
                markers[mapHoverId].getElement().classList.add('marker-hover');
                markers[mapHoverId].getElement().classList.remove('marker');
                setPreviousHoverId(mapHoverId);
            }
        }
      }, [mapHoverId, hover]);

    useEffect(() => {
        if (selectedEvent.place_id) {
            zoomToPlace(selectedEvent);
        }
    }, [selectedEvent])

    return (

        <div id="main-map">
            <div ref={mapContainer} style={{width: '100%', height: '100%'}} />
        </div>
    );


}

export default MapBox;