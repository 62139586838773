import { PlaceEvent } from "./place";
import "./events.css"

interface EventCardProps {
    index: number;
    place: PlaceEvent;
    hover: boolean;
    setHover: React.Dispatch<React.SetStateAction<boolean>>;
    mapHoverId: string;
    setMapHoverId: React.Dispatch<React.SetStateAction<string>>;
    setSelectedFromClick: (place_id: string) => void;
    setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
    hasDay: boolean;
}

function EventCard({ index, place, hover, setHover, mapHoverId, setMapHoverId, setSelectedFromClick, setShowDetails, hasDay }: EventCardProps) {

  const placeImageUrl = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=500&photo_reference=${place.photoRefs![0]}&key=AIzaSyDpbji6kHeHeojfvCAuKhuq4O6mNJ2rdZQ`
    
  const handleHover = () => {
    setMapHoverId(place.place_id!);
    setHover(true);
  }
  const handleHoverOut = () => {
    setHover(false);
  }

  const handleCardClick = () => {
    setSelectedFromClick(place.place_id!)
    setShowDetails(true)
  }

  const dayToColorProjcard = (day: number) => {
    switch (day) {
      case 1:
        return "blue";
      case 2:
        return "green";
      case 3:
        return "yellow";
      case 4:
        return "orange";
      case 5:
        return "red";
      case 6:
        return "orange";
      case 7:
        return "brown";
      case 8:
        return "grey";
      default:
        return "blue";
    }
  }
  
  const isCardHovered = hover && mapHoverId === place.place_id;

  return (
    <div>
      <div>
        <span className="index__circle">{index+1}</span>
      </div>
      <div onClick={handleCardClick} onMouseEnter={handleHover} onMouseLeave={handleHoverOut} className={isCardHovered ? 'card card-hover' : 'card'}>
        <div className="card__content">
        <p className="card__description">{place.definition}</p>
        {hasDay ? (
            <p className="card__date">Day {place.mappedDay}</p>
        ) : null}
          <p className="card__title">{place.name}</p>
        </div>
        <img src={placeImageUrl} className={isCardHovered ? 'card__background-hover' : 'card__background'} />
      </div>
    </div>

  )
}

export default EventCard