import {useRef, useEffect} from "react";
import { PlaceEvent } from "./place"
import EventBox from "./eventCard";

interface PlaceListProps {
    places: PlaceEvent[];
    hover: boolean;
    setHover: React.Dispatch<React.SetStateAction<boolean>>;
    mapHoverId: string;
    selectedEvent: PlaceEvent;
    setMapHoverId: React.Dispatch<React.SetStateAction<string>>;
    setSelectedFromClick: (place_id: string) => void;
    setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
    hasDay: boolean;
  }

function EventCardList ({ places, hover, setHover, mapHoverId, selectedEvent, setMapHoverId, setSelectedFromClick, setShowDetails, hasDay}: PlaceListProps){

  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
      // Scroll into view when the selected event changes
      const index = places.findIndex(place => place.place_id === mapHoverId);
      itemRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedEvent]);

    useEffect(() => {
      // Scroll to the last event when the list changes
      itemRefs.current[places.length - 1]?.scrollIntoView({ behavior: 'smooth' });
    }, [places]);

  // iterate per place and return a list of EventBoxes if place_id exists
  const items = places.map((place, index) => {
    if (place.place_id) {
      return (
        <div key={place.place_id} ref={ref => itemRefs.current[index] = ref}>
          <EventBox
            index={index}
            place={place}
            hover={hover}
            setHover={setHover}
            mapHoverId={mapHoverId}
            setMapHoverId={setMapHoverId}
            setSelectedFromClick={setSelectedFromClick}
            setShowDetails={setShowDetails}
            hasDay={hasDay}
            />
        </div>
      );
    }
  });

    return <div className="eventlist"> {items} </div>;
}

export default EventCardList